<template>
  <div ref="preview" class="hk-preview pswp" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="pswp__bg" />
    <div class="pswp__scroll-wrap">
      <div class="pswp__container">
        <div class="pswp__item" />
        <div class="pswp__item" />
        <div class="pswp__item" />
      </div>
      <div class="pswp__ui pswp__ui--hidden">
        <div class="pswp__top-bar">
          <div class="pswp__counter" />
          <div class="pswp__preloader">
            <div class="pswp__preloader__icn">
              <div class="pswp__preloader__cut">
                <div class="pswp__preloader__donut" />
              </div>
            </div>
          </div>
        </div>
        <div class="pswp__caption">
          <div class="pswp__caption__center" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PhotoSwipe from 'photoswipe/dist/photoswipe'
import PhotoSwipeDefault from 'photoswipe/dist/photoswipe-ui-default'
export default {
  name: 'HkPreview',
  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    },
    options: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      el: null,
      photoSwipe: null,
      images: []
    }
  },
  watch: {
    data: {
      handler (val, oldVal) {
        this.revokeImages(oldVal)
        this.images = val.map(item => {
          if (item instanceof File) {
            return {
              src: URL.createObjectURL(item),
              w: 0,
              h: 0
            }
          }
          return typeof item === 'string' ? {
            src: item,
            w: 0,
            h: 0
          } : {
            src: item.src instanceof File ? URL.createObjectURL(item.src) : item.src,
            w: item.width,
            h: item.height
          }
        })
      },
      immediate: true
    }
  },
  mounted () {
    this.el = this.$refs.preview
    document.body.appendChild(this.$el)
  },
  destroyed () {
    this.revokeImages(this.images)
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el)
    }
  },
  methods: {
    async show (index) {
      if (!this.images[index].w || !this.images[index].h) {
        const image = await this.handleOrientation(this.images[index].src)
        this.images[index] = { ...this.images[index], ...image }
        this.photoSwipeInit(index)
      } else {
        this.photoSwipeInit(index)
      }
    },
    close () {
      this.photoSwipe.close()
    },
    photoSwipeInit (index) {
      this.photoSwipe = new PhotoSwipe(this.$el, PhotoSwipeDefault, this.images, {
        history: false,
        shareEl: false,
        tapToClose: true,
        bgOpacity: 0.8,
        errorMsg: '<div class="pswp__error-msg">图片加载失败</div>',
        index,
        ...this.options
      })
      this.photoSwipe.listen('gettingData', async (index, item) => {
        if (!item.loading && (!item.w || !item.h)) {
          const { src, w, h } = await this.handleOrientation(item.src)
          item.src = src
          item.w = w
          item.h = h
          this.photoSwipe.updateSize()
        }
      })
      this.photoSwipe.init()
    },
    handleOrientation (src) {
      return new Promise((resolve, reject) => {
        const image = new Image()
        image.setAttribute('crossOrigin', 'Anonymous')
        image.onload = () => {
          const width = image.naturalWidth
          const height = image.naturalHeight
          resolve({
            src,
            w: width,
            h: height
          })
        }
        image.onerror = err => reject(err)
        image.src = src
      })
    },
    revokeImages (images) {
      if (!images) {
        return
      }
      for (const item of images) {
        if (item.src && item.src.startsWith('blob:')) {
          URL.revokeObjectURL(item.src)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~photoswipe/dist/photoswipe.css';
@import '~photoswipe/dist/default-skin/default-skin.css';

.hk-preview {
  .pswp__ui {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;

    .pswp__top-bar {
      height: 100%;
      background-color: transparent;

      .pswp__counter {
        width: 100%;
        font-size: $textSize;
        text-align: center;
      }
    }

    .pswp__preloader {
      position: relative;
      left: auto;
      top: auto;
      margin: 0;
      float: right;
    }
  }
}
</style>
